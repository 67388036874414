import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { dropTypes } from '../../../../../../utils/dropTypes';
import styles from './styles.module.scss';
import useSnippetAtPageDND from '../../../../../../hooks/useSnippetAtPageDND';

const SnippetAtPage = ({
  snippetAtPage, snippet, index, children,
}) => {
  const is_container = snippet?.is_container;
  const ref = useRef(null);

  const {
    onEdit,
  } = useSnippetAtPageDND();

  const {
    SNIPPET_AT_PAGE_CONTAINER, SNIPPET_AT_PAGE_CHILD, SNIPPET_AT_PAGE, SNIPPET_AT_PAGE_CONTAINER_CHILD,
  } = dropTypes;

  const { id, parent } = snippetAtPage;

  const isChild = Boolean(parent);

  const type = is_container
    ? (
      isChild ? SNIPPET_AT_PAGE_CONTAINER_CHILD : SNIPPET_AT_PAGE_CONTAINER)
    : (
      isChild
        ? SNIPPET_AT_PAGE_CHILD
        : SNIPPET_AT_PAGE
    );

  const accept = [SNIPPET_AT_PAGE_CHILD, SNIPPET_AT_PAGE_CONTAINER_CHILD, SNIPPET_AT_PAGE, SNIPPET_AT_PAGE_CONTAINER];

  const [, drop] = useDrop({
    accept,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      const dragParent = item.snippetAtPage.parent;
      const hoverParent = parent;
      // Don't replace items with themselves
      if (id === item?.snippetAtPage?.id) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragParent === hoverParent && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragParent === hoverParent && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      // onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (dragItem, monitor) => {
      if (!monitor.didDrop()) {
        const dragParent = dragItem.snippetAtPage.parent;
        const hoverParent = parent;

        const order = (hoverParent && dragParent !== hoverParent) ? null : dragItem.index + 1;

        /* if (dragItem.type === SNIPPET_AT_PAGE) {
          console.log(`change order ${SNIPPET_AT_PAGE};
          is_container${is_container}; is_child: ${isChild}`, order);
        }

        if (dragItem.type === SNIPPET_AT_PAGE_CONTAINER) {
          console.log(`change order ${SNIPPET_AT_PAGE_CONTAINER};
          is_container${is_container}; is_child: ${isChild}`, order);
        }

        if (dragItem.type === SNIPPET_AT_PAGE_CHILD) {
          console.log(`change order ${SNIPPET_AT_PAGE_CHILD}; is_container${is_container};
           is_child: ${isChild}`, order, dragItem, snippetAtPage);
        } */

        if (
          dragItem.type === SNIPPET_AT_PAGE
          || dragItem.type === SNIPPET_AT_PAGE_CONTAINER
          || dragItem.type === SNIPPET_AT_PAGE_CONTAINER_CHILD
          || dragItem.type === SNIPPET_AT_PAGE_CHILD
        ) {
          if (order !== dragItem.snippetAtPage.order) {
            const data = {
              ...dragItem.snippetAtPage,
              parent: hoverParent,
              order,
            };
            onEdit(data);
          }
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type,
      id,
      snippetAtPage,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div
      className={styles.container}
      ref={ref}
      style={{ opacity }}
    >

      {children}

    </div>
  );
};

export default SnippetAtPage;
